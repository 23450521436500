/* Hide scrollbar for Chrome, Safari and Opera */
.ultimate_ttt_container {
    width: 100%;
    height: 100%;
    background-color: white;
}

.ultimate_ttt_grid {
    border: 2px solid black;
    width: 90%;
    aspect-ratio: 1;
    
}

.ultimate_ttt_grid td {
    border: 2px solid black;
}