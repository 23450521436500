/* Hide scrollbar for Chrome, Safari and Opera */
.terminal_screen::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.terminal_screen {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    background: black;
    width: 100%;
    height: 100%;
    color: white;
    padding: 0.5rem;
}