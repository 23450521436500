@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

.nowrap {
    white-space: nowrap;
}

#welcome-window * {
    font-family: Manrope, sans-serif;
}

.welcome-page {
    position: absolute;
    top: 0;   
}
.welcome-page:not(.animate) {
    left: -100%;
}
.welcome-page.active:not(.animate) {
    left: 0;
}

.welcome-page.animate {
    animation: fadeOut 0.5s 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.welcome-page.animate > * {
    animation: fadeOut 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.welcome-page.active.animate {
    animation: fadeIn 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.welcome-page.active.animate > * {
    opacity: 0;
    animation: fadeIn 0.5s 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.welcome-page1::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./assets/bkg.png);
    background-size: cover;
    transform:scaleX(-1);
    opacity: 0.6;
}

.welcome-page2::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./assets/bkg2.png);
    background-size: cover;
    background-position: center;
    opacity: 1;
}

.welcome-page1 {
    opacity: 1;
}

.welcome-page1 hr {
    border-color: rgb(102, 102, 102);
    animation: w-expand 0.6s 0.3s ease-out;
    animation-fill-mode: forwards;
}

.welcome-page1 .welcome-text {
    transform: translateY(100%);
    animation: slideUp 0.5s 0.9s ease-out;
    animation-fill-mode: forwards;
}

.welcome-page1 .desc-text {
    transform: translateY(-100%);
    animation: slideDown 0.5s 1s ease-out;
    animation-fill-mode: forwards;
}

.welcome-page1 .explore-btn {
    opacity: 0;
    animation: fadeIn 0.6s 1.5s ease-in-out;
    animation-fill-mode: forwards;
}

#page2-text {
    font-weight: 800;
    background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(15, 253, 217));
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;   
}

.animate #page2-textcontainer {
    height: 2rem;
    clip-path: inset(0 100% 0 0);
    animation: expand 1.2s 1s ease-out forwards;
    opacity: 1;
    overflow: hidden;
    display: inline-block;
}

.welcome-page3 {
    background: rgb(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
}


@keyframes w-expand {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes expand {
    0% {
        clip-path: inset(0 100% 0 0);
    }
    100% {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes expand-h {
    0% {
        clip-path: inset(0 0 100% 0);
    }
    100% {
        clip-path: inset(0 0 0 0);
    }
}



@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }   
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }   
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }    
}

:root {
    --border-wid: 5px;
}
  
  
.curly-brace {
    position: absolute;
}

.curly-brace .brace {
    border-bottom: var(--border-wid) solid;
    margin: 0 10%;
    width: 30%;
    height: 50%;
    float: left;
}
  
.curly-brace .brace:before,
.curly-brace .brace:after {
    content: "";
    width: 12%;
    height: calc(50% + var(--border-wid));
    position: absolute;
    display: block;
    box-sizing: border-box;
}

.curly-brace .brace.left:before {  
    border-bottom: var(--border-wid) solid;  
    border-left: var(--border-wid) solid;
    border-bottom-left-radius: 30px;
    left: 0;
    bottom: calc(50%);
}
  
.curly-brace .brace.left:after {
    border-top: var(--border-wid) solid;
    border-right: var(--border-wid) solid;
    border-top-right-radius: 20px;
    top: calc(50% - var(--border-wid));
    height: 50%;
    right: calc(50% - var(--border-wid)/2);
}

.curly-brace .brace.right:before {
    border-top: var(--border-wid) solid;
    border-top-left-radius: 20px;
    border-left: var(--border-wid) solid;
    left: calc(50% - var(--border-wid)/2);
    top: calc(50% - var(--border-wid));
    height: 50%;
}
  
.curly-brace .brace.right:after {
    border-bottom: var(--border-wid) solid;
    border-right: var(--border-wid) solid;
    border-bottom-right-radius: 30px;
    right: 0;
    bottom: calc(50%);
}

.arrow {
    height:26px;
    display: flex;
}
.arrow:before {
    content: "";
    background: currentColor;
    width:50%;
    clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px));
}

.welcome-page3.active .f1 {
    width: 0;
    opacity: 0;
    --w: 10rem;
    animation: 0.5s 1s f1-expand ease-out forwards !important;
}

.welcome-page3.active .f2 {
    opacity: 0;
    animation: 0.5s 1.5s fadeIn ease-out forwards !important;
}

.welcome-page3.active .f3 {
    width: 0;
    opacity: 0;
    --w: 23rem;
    animation: 0.8s 2s f1-expand ease-out forwards !important;
}

.welcome-page3.active .f4 {
    opacity: 0;
    animation: 0.5s 2.8s fadeIn ease-out forwards !important;
}

.welcome-page3.active .f5 {
    width: 0;
    opacity: 0;
    --w: 20rem;
    animation: 0.8s 3.2s f1-expand ease-out forwards !important;
}

.welcome-page3.active .f6 {
    opacity: 0;
    animation: 0.5s 4s fadeIn ease-out forwards !important;
}

.welcome-page3.active .f7 {
    width: 0;
    opacity: 0;
    --w: 27rem;
    animation: 0.8s 4.8s f1-expand ease-out forwards !important;
}

.welcome-page3.active .f8 {
    opacity: 0;
    animation: 0.5s 5.2s fadeIn ease-out forwards !important;
}

.welcome-page3.active .f9 {
    opacity: 0;
    animation: 0.5s 6s fadeIn ease-out forwards !important;
}


@keyframes f1-expand {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: var(--w);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .animate #page2-textcontainer {
        clip-path: inset(0 0 100% 0);
        animation: expand-h 1.2s 1s ease-out forwards;
        height: unset;
    }
}